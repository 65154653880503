import React, { useEffect, useState } from "react";
import { API } from "../../constant";
import { fetchWithAuth } from "./../../api/fetchWithAuth"
import {
  Content, Row, SimpleTable, Col, Box, Inputs, Button
} from 'adminlte-2-react';
import ModalDialog from '../../components/Modal/ModalDialog'
import moment from 'moment';
const {
  Text, Select
} = Inputs;

const columns = [
  { title: 'Имя', data: 'name' },
  { title: 'ID Modes terminal', data: 'ID_Modbus' },
  { title: 'Дата создания', data: 'createdAt' },
];

const Units = (props) => {
  const { updateMenu, isDiagram } = props;
  
  const [units, setUnits] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [candidateforDeletion, setCandidateforDeletion] = useState();

  const fetchUnits = async () => {
    try {
      const response = await fetchWithAuth(`${API}/units?filters[isDiagram][$eq]=${isDiagram}`);
      let data = await response.json();

      if (typeof data !== 'undefined' && data.data.length > 0) {
        const unitsData = data.data.map(item => ({
          id: item.id,
          ID_Modbus: item.attributes.ID_Modbus,
          name: `${isDiagram ? 'Схема' : 'Блок'}_${item.attributes.name}`,
          createdAt: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        }));
        unitsData.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
        setUnits(unitsData);
      }
      else {
        setUnits([{ name: isDiagram ? 'схемы отсутствуют' : 'блоки отсутствуют'}]);
      }

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
      fetchUnits();
  }, []);

  const toggleConfirmDialog = () => {
    setShowConfirmDialog(prevState => !prevState);
  }

  const getText = () => {
    if (!candidateforDeletion) {
      return "";
    }

    return `Вы уверены что хотите удалить ${units.find(item => item.id === candidateforDeletion).name}`
  }

  const remove = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };

    const response = await fetchWithAuth(`${API}/units/${candidateforDeletion}`, requestOptions);
    if (response.ok) {
      setCandidateforDeletion(null);
      toggleConfirmDialog();
      fetchUnits();
      await updateMenu();
    }
  }

  const handleRemove = (id) => {
    setCandidateforDeletion(id)
    toggleConfirmDialog();
  };

  return (
    <Content title={isDiagram ? 'Добавление схемы' : 'Добавление блока'} >
      <ModalDialog
        showPrimaryBox={false}
        modalSize="md"
        title="Подтверждение удаления"
        show={showConfirmDialog}
        onHide={toggleConfirmDialog}
        modalFooter=
        {
          <div className="box-footer">
            <Button text="Отменить" onClick={toggleConfirmDialog} pullLeft />
            <Button text="Подтвердить удаление" onClick={remove} type="info" pullRight />
          </div>
        }
      >
      <div style={{ marginLeft: 10, whiteSpace: 'pre-wrap' }}> {getText()} </div>
      </ModalDialog>
      <Box type="primary" title="">
        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            <Button className="unstyled-button" to={isDiagram ? '/diagram/new': '/unit/new'} text= {isDiagram ? "Создать схему" : "Создать блок"} icon="fa-plus-circle" />
            <SimpleTable
              hover
              condensed
              columns={columns.concat([{
                title: '',
                data: 'id',
                render: id => id && <><Button icon="fa-trash" onClick={() => handleRemove(id)} className="clickable" /><Button to={isDiagram ? `/diagram/edit/${id}` :`/unit/edit/${id}`} icon="fa-cogs" className="clickable" /></>,
              }])}
              data={units}
            />
          </Col>
          <Col md={3}> </Col>
        </Row>

      </Box>
    </Content>
  );
}

export default Units;
